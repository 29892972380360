'use strict';

/* Swaven Reactivity Engine */

window.sre = (function() {

  /*
  property-to-nodes mapping.
  key: (string) property name.
  value: list of ExressionGroup. Different kind of groups.
  for-loop: {
    expr (string, js expression),
    tmpls (list of template nodes),
    listPath (array of keys to get list object in app context, e.g. ['store', 'avails']),
    itemName (string, name of iteration element),
    iterName (string, name of iterator variable)
    scopeId (string, id of scope if expression is used inside a loop)
  }
  other directives: {
    expr (string, js expression),
    nds (list of {nd (DOM node), rule (string, directive name)} ),
    scopeId
  }
  */
  const mapping = new Map()

  // scopes map: each element is an object with:
  // - items (list)
  // - map (map of js expressions & functions).
  // - nodes: map of dom elements (from template fragments), to an object with props:
  //    - nds: array of of the corresponding instance nodes.
  const scopes = new Map()

  const eventMapping = new Map()

  // associates some metadata to a DOM node (key)
  // {[eventType]: {
  //   expr: string, expression in event handler
  //   params: array, parameters to pass to handler
  // }}
  const domStates = new WeakMap()

  // associates DOM nodes to the comments that replaces them when not inserted via x-if/x-else
  const ifMap = new WeakMap()

  // expression to Function object mapping.
  // for event handler expressions, the value is an object with the fn property (the Function object)
  const fnMap = new Map()

  // watchers map: key is prop to watch, value is an array of functions
  // when a property is updated, all related functions are executed.
  const watchers = new Map()

  let propRxDefinition // property matching regex definition
  let forloopRx // parse for-loop directives

  // map [expression group] => [{nd,rule}]
  // stores nodes to render at init, without waiting for a state change.
  let prerenderMap = new Map()

  let app = null
  let logger

  /** ***************************************
   * entry point
   *****************************************/
  async function start(obj, _logger) {
    performance.mark('sre-start')
    app = obj
    const props = Object.keys(app._store) // prop names to observe

    logger = _logger || console

    // match occurrences of all properties of the store proxy object
    propRxDefinition = `(?:^|[^\\.])\\bstore\\.(${props.join('|')})\\b`
    forloopRx = /^\((?<item>[a-zA-Z_]\w*)(?:, *(?<iter>[a-zA-Z_]\w*))?\) *of +(?<list>.+)$/

    parseNode()
    prerender()

    propRxDefinition = forloopRx = undefined // clean regexp objects after use

    app.store = new Proxy(app._store, handler)
    performance.measure('sre-end', 'sre-start')
    logger.log(`sre-init`, {
      duration: Math.round(performance.getEntriesByName('sre-end', 'measure')[0].duration),
    })

    return app
  }

  // Add the provided function to watchers mapping for each property
  function watch(obj) {
    Object.entries(obj).forEach(([prop, fn]) => {
      const w = watchers.get(prop)

      if (w)
        w.push(fn)
      else
        watchers.set(prop, [fn])
    })
  }

  // force initial rendering of x-if/elses outside templates.
  function prerender() {
    if (prerenderMap.size > 0) {
      performance.mark('init-if-start')
      const entries = prerenderMap.entries()
      let entry = entries.next()

      while (!entry.done) {
        const [grp, nds] = entry.value
        const fn = fnMap.get(grp.expr)
        const res = fn.call(null, app.data, app._store)

        for (let i = 0; i < nds.length; i++) {
          updateNode(nds[i].nd, nds[i].rule, res)
        }
        entry = entries.next()
      }
      performance.measure('init-if', 'init-if-start')
      logger.log('prerender', { duration: Math.round(performance.getEntriesByName('init-if', 'measure')[0].duration) })
    }
    prerenderMap = null // get rid of the object and make it available for GC
  }

  // Analyzes DOM element. Sends to mapNode (initial parsing) or mapScopedNode (parsing templated content)
  function parseNode(root, scopeId, context) {
    const isDoc = !root

    // no root given: set on document
    if (isDoc) {
      root = document

      // nested for loops are not supported
      for (const nd of root.querySelectorAll('template[x-for]')) {
        mapNode(nd, 'x-for', scopeId, context)
      }
    }

    const serialRoot = isDoc ? root.body : root
    const matches  = serialRoot.outerHTML.match(/\b(x-(?!for)[a-z_][\w_-]+)/gi)

    if (matches) {
      const dynAttrs = matches.reduce((known, cur) => {
        if (!known.includes(cur))
          known.push(cur)

        return known
      }, [])

      for (let i = 0; i < dynAttrs.length; i++) {
        // process root node if it has the attribute (querySelectorAll cannot return called node itself). For non-document root only.
        if (!isDoc && root.hasAttribute(dynAttrs[i]))
          mapNode(root, dynAttrs[i], scopeId, context)

        // process child nodes
        for (const nd of root.querySelectorAll(`[${dynAttrs[i]}]`)) {
          mapNode(nd, dynAttrs[i], scopeId, context)
        }
      }
    }

    parseEvents(isDoc ? root.body : root, context)
    if (isDoc)
      bindEvents(root.body)
  }

  // extract args from a function call in an event handler expression.
  // ignores all non-variable names (strings, numbers, expressions, function calls, etc.)
  function getEventArgs(expr) {
    const start = expr.indexOf('('),
      end = expr.lastIndexOf(')'),
      str = expr.substring(start+1, end),
      rx = /^\s*[a-zA-Z_\$][\w\.]*\s*$/ // eslint-disable-line no-useless-escape
    const elems = str.split(',')
    const args = []

    for (let i = 0 ; i < elems.length; i++) {
      if (rx.test(elems[i]))
        args.push(elems[i].trim())
    }

    return args
  }

  function parseEvents(nd, context) {
    const events = ['click', 'keypress', 'slider-select']

    for (let i = 0; i < events.length; i++) {
      const evt = events[i]

      if (nd.hasAttribute('@' + evt)) {
        const expr = nd.getAttribute('@' + evt)
        const args = getEventArgs(expr)

        try {
          if (!fnMap.has(expr)) {
            const o = { fn: Function(...args, `"use strict"; return (${expr});`) };

            fnMap.set(expr, o)
          }

          const evtMapping  = {
            args: args.slice(1),
          }

          if (!eventMapping.has(expr))
            eventMapping.set(expr, evtMapping)
        }
        catch (ex) {
          logger.error(`${evt}="${expr}"`, ex)
        }
      }
    }

    for (let i = 0; i < nd.childElementCount; i++) {
      parseEvents(nd.children[i], context)
    }
  }

  // checks directive value on node and adds the corresponding mapping to prop & expression
  function mapNode(nd, directive, scopeId, context) {

    let expr = nd.getAttribute(directive)

    if (scopeId && expr) {
      try {
        if (!fnMap.has(expr)) {
          fnMap.set(expr, Function('data', 'store', ...context,
            `"use strict"; return (${expr});`,
          ))
        }
      } catch (ex) {
        logger.error(`${directive}="${expr}"`, ex)
      }
    }

    try {
      // for loop: configure scope for later rendering
      if (directive === 'x-for') {
        scopeId = Math.random().toString(36).substring(2)
        scopes.set(scopeId, {
          items: null,
          nodes: new Map(), // map templated nodes & their instances
        })
        nd.dataset.scope = scopeId
      }
      // template node (e.g. grouped x-if): need to parse its content
      else if (nd.tagName === 'TEMPLATE') {
        for (let i = 0; i < nd.content.childElementCount; i++) {
          parseNode(nd.content.children[i], scopeId, context)
        }
      }

      if (directive === 'x-else') {
        expr = nd.previousElementSibling.getAttribute('x-if')
      }

      let m
      const rx = new RegExp(propRxDefinition, 'g')

      // keep trace of found properties, to add them only once if they're referenced multiple times
      const propsInExpr = []

      do {
        m = rx.exec(expr)

        if (m && !propsInExpr.includes(m[1])) {
          const prop = m[1]

          propsInExpr.push(prop)

          const elem = { nd, rule: directive }
          const data = mapping.get(prop)
          let exprGrp

          // add node to mapping
          if (data) {
            exprGrp = data.find(x => x.expr === expr)

            if (exprGrp) {
              if (exprGrp.nds)
                exprGrp.nds.push(elem)
              else
                logger.error(`directive='${directive}'`, new Error('mapping error'))
            }
            else {
              exprGrp = buildExpressionGroup(expr, elem, scopeId, context)
              data.push(exprGrp)
            }
          }
          else {
            // create new mapping object
            exprGrp = buildExpressionGroup(expr, elem, scopeId, context)
            mapping.set(prop, [exprGrp]) // update mapping with new node to watch
          }

          // mark if/elses for pre-rendering
          if (!scopeId && (directive === 'x-if' || directive === 'x-else')) {
            const nds = prerenderMap.get(exprGrp)

            if (nds)
              nds.push(elem)
            else
              prerenderMap.set(exprGrp, [elem])
          }
        }
      }
      while (m)

      // if node is not inside a scope, remove directive attribute to lighten the DOM.
      // Do not remove for x-if directives that have a x-else sibling, as it will be needed.
      // For scoped nodes, directives are removed when rendered.
      if (!scopeId  && (directive != 'x-if' || !nd.nextElementSibling || !nd.nextElementSibling.hasAttribute('x-else')))
        nd.removeAttribute(directive)
    }
    catch (ex) {
      logger.error('mapNode error', ex)
    }
  }

  function buildExpressionGroup(expr, elem, scopeId, context = []) {
    const forMatch = expr.match(forloopRx)

    // for loop mapping data
    if (forMatch) {
      const iter = forMatch.groups.iter || 'i'

      // parse all template's children
      for (let i = 0; i < elem.nd.content.childElementCount; i++) {
        parseNode(elem.nd.content.children[i], scopeId, [forMatch.groups.item, iter])
      }
      const localScope = scopes.get(scopeId)

      localScope.iterName = iter
      localScope.itemName = forMatch.groups.item

      return {
        expr,
        tmpls: [elem.nd],
        listPath: forMatch.groups.list.split('.'),
        itemName: forMatch.groups.item,
        iterName: iter,
        scopeId,
      }
    }

    // mapping data for other directives
    try {
      if (!fnMap.has(expr)) {
        fnMap.set(expr, Function('data', 'store', ...context,
          `"use strict"; return (${expr});`,
        ))
      }

      return {
        expr,
        nds: [elem],
        scopeId,
      }
    }
    catch (ex) {
      logger.error(`${elem.rule}="${expr}"`, ex)
    }
  }

  function processLoop(exprGrp, oldLength) {
    let list = app

    for (let i = 0; i < exprGrp.listPath.length; i++)
      list = list[exprGrp.listPath[i]]

    if (list == null)
      return

    for (let tplIdx = 0; tplIdx < exprGrp.tmpls.length; tplIdx++) {
      const localScope = scopes.get(exprGrp.scopeId)

      localScope.items = list

      // templated nodes are added to a fragment, which is then inserted in the document.
      // Only one DOM insertion, better for perfs.
      const sourceNd = exprGrp.tmpls[tplIdx].content.firstElementChild

      // previous sibling of the current node.
      // First iteration: template node; following iterations: node created by previous iteration
      let previousSibling = exprGrp.tmpls[tplIdx]

      for (let iter = 0; iter < list.length; iter++) {
        // instanciate template
        const nd = sourceNd.cloneNode(true)

        renderNode(nd, exprGrp.scopeId, [list[iter], iter], sourceNd, list.length)
        bindEvents(nd, {
          [exprGrp.itemName]: list[iter],
          [exprGrp.iterName]: iter,
        })
        nd.classList.add(exprGrp.scopeId)

        // replaces existing node if possible, or inserts at end of list
        const prev = exprGrp.tmpls[tplIdx].parentElement.children[iter+1]

        if (prev && prev.classList.contains(exprGrp.scopeId))
          prev.replaceWith(nd)
        else
          previousSibling.insertAdjacentElement('afterend', nd)

        previousSibling = nd
      }

      // remove old nodes if new list is shorter than the previous one.
      for (let i = oldLength - list.length  ; i > 0; i--) {
        exprGrp.tmpls[tplIdx].parentElement.children[list.length + 1].remove()
      }

      // resize mapped node arrays if needed, to trim old nodes
      const scoped = localScope.nodes.get(sourceNd)

      if (scoped && scoped.nds.length > list.length) {
        for (const { nds } of localScope.nodes.values()) {
          nds.length = list.length
        }
      }
    }
  }

  // renders the template's content
  function renderTemplate(tmpl, scopeId, localScope) {
    const frag = document.createDocumentFragment()

    for (let i = 0; i < tmpl.content.children.length; i++) {
      const childNd = tmpl.content.children[i].cloneNode(true)

      renderNode(childNd, scopeId, localScope, tmpl.content.children[i])

      // insert comment if node is not to be rendered.
      /* TODO: this addresses the specific case of x-if in a template.
          It needs to be generalized as full initial rendering.
      */
      if (!ifMap.has(childNd))
        frag.appendChild(childNd)
      else
        frag.appendChild(ifMap.get(childNd))
    }
    tmpl.after(frag)
  }

  function renderNode(nd, scopeId, localScope, sourceNd, listLength) {
    const attrs = nd.getAttributeNames()
    let isReactive = false
    let isReacIter = 0

    while (!isReactive && isReacIter < attrs.length) {
      if (attrs[isReacIter++].startsWith('x-'))
        isReactive = true
    }

    if (isReactive || nd.tagName === 'TEMPLATE') {
      const iterator = localScope[1]
      const scopedNodes = scopes.get(scopeId).nodes

      // create new array of nodes when watched list is updated and first item is processed.
      if (listLength != null && iterator === 0)
        scopedNodes.set(sourceNd, { nds: new Array(listLength) })

      // if node uses x-if directive, process it first.
      // then we can ignore other directives & children if it is not rendered.
      const ifIdx = attrs.indexOf('x-if')

      if (ifIdx > -1) {
        attrs.splice(ifIdx, 1)

        // take ref to node next sibling, in case it is removed from fragment.
        let nextSibling

        if (nd.nextElementSibling && nd.nextElementSibling.hasAttribute('x-else'))
          nextSibling = nd.nextElementSibling

        const insert = render(nd, 'x-if', scopeId, localScope)

        if (nextSibling) {
          if (insert)
            nextSibling.remove()
          else {
            // remove the x-else is enough tomake a standard element visible,
            // but template elements require to render all of their children.
            nextSibling.removeAttribute('x-else')
            if (nextSibling.tagName === 'TEMPLATE')
              renderTemplate(nextSibling, scopeId, localScope)
          }
        }

        if (insert && nd.tagName === 'TEMPLATE')
          renderTemplate(nd, scopeId, localScope)

        if (!insert) {
          if (!scopedNodes.has(sourceNd))
            scopedNodes.set(sourceNd, { nds: new Array(listLength) })

          // scopedNodes use the comment node that replaces the element,
          // and delete the map entry, it was only needed to retrieve the comment
          scopedNodes.get(sourceNd).nds[iterator] = ifMap.get(nd)
          ifMap.delete(nd)

          return // no need to process node and children
        }

        nd.removeAttribute('x-if')
      }

      // process other directives for the node
      for (let i = 0; i < attrs.length; i++) {
        const attr = attrs[i]

        if (!!attr && attr.startsWith('x-')) {
          render(nd, attr, scopeId, localScope)
          nd.removeAttribute(attr)
        }
      }

      // Create mapping if required. Happens if a rendered x-if contains reactive children.
      if (!scopedNodes.has(sourceNd))
        scopedNodes.set(sourceNd, { nds: new Array(listLength) })

      // keep node at the correct index for its source node.
      scopedNodes.get(sourceNd).nds[iterator] = nd
    }

    // process node's children
    const children = [...nd.children] // get a static ref to children nodes

    for (let i = 0; i < children.length; i++) {
      // process only if node has a parent. Otherwise it means it's been removed.
      if (children[i].parentElement)
        renderNode(children[i], scopeId, localScope, sourceNd.children[i], listLength)
    }
  }

  function render(nd, attr, scopeId, localData) {
    const expr = nd.getAttribute(attr)
    const fn = fnMap.get(expr)

    if (!fn) {
      logger.error(expr, new Error('Expression not mapped: '))

      return
    }

    try {
      const res = fn.call(null, app.data, app.store, ...localData)

      return updateNode(nd, attr, res)
    }
    catch (ex) {
      logger.error(`render error [fn=${expr}]`, ex)

      return false
    }
  }

  function bindEvents(nd, localScope) {
    const events = ['click', 'keypress', 'slider-select']

    for (const evt of events) {
      const attr = '@'+ evt

      if (nd.hasAttribute(attr)) {
        const expr = nd.getAttribute(attr)
        const { args } = eventMapping.get(expr)
        const params = []

        for (const arg of args) {
          if (localScope && localScope.hasOwnProperty(arg)) { // eslint-disable-line no-prototype-builtins
            const scopeData = localScope[arg]

            params.push(scopeData)
          }
          else if (arg.startsWith('data'))
            params.push(app.data)
          else if (arg.startsWith('store'))
            params.push(app.store)
        }

        const ndState = domStates.get(nd)

        if (ndState)
          ndState[evt] = { expr, params }
        else
          domStates.set(nd, { [evt]: { expr, params } })

        const useCapture = nd.dataset.capture != null && nd.dataset.capture !== 'false'

        nd.addEventListener(evt, e => {
          const state = domStates.get(e.currentTarget)

          if (!state || !state[e.type]) {
            logger.log('warn', { message: `no event data for ${e.type}`, level: 'warn' })

            return
          }
          const { fn } = fnMap.get(state[e.type].expr)
          const params = state[e.type].params

          return fn(e, ...params)
        }, useCapture)

        nd.removeAttribute(attr)
      }
    }

    for (let i = 0; i < nd.childElementCount; i++) {
      bindEvents(nd.children[i], localScope)
    }
  }

  function updateNode(nd, rule, result) {
    let insert = true

    switch (rule) {
      case 'x-show':
        if (nd.nodeType !== Node.COMMENT_NODE && nd.dataset.transition != null && !result) {
          setTimeout(() => { nd.classList.toggle('hidden', !result) }, 400)
        }
        else if (nd.nodeType !== Node.COMMENT_NODE)
          nd.classList.toggle('hidden', !result)
        break
      case 'x-text':
        nd.textContent = result
        break
      case 'x-else':
      // no break, use x-if logic with flipped result
        result = !result
      case 'x-if': // eslint-disable-line no-fallthrough
        if (!result) {
          insert = false

          // An  update can be triggered when value does not change (when object changes but not the used property, e.g. array reassign with same length).
          // The node can already be commented, so we first look in ifMap before creating a new comment.
          // If we create a new comment each time, we lose reference to the inserted comment when overwriting ifmap.
          const comm = ifMap.get(nd) || document.createComment('')

          // insert comment in place of node
          if (nd.parentElement)
            nd.replaceWith(comm)

          // necessary so that renderNode can retrieve the comment node and reference it in scoppedNodes
          ifMap.set(nd, comm)
        }
        else {
        // re-insert node
          const comNd = ifMap.get(nd)

          if (comNd) {
            comNd.replaceWith(nd)
            ifMap.delete(nd)
          }
        }
        break

      case 'x-class':
        Object.entries(result).forEach(([className, isActive]) => {
          nd.classList.toggle(className, !!isActive)
        })
        break
      default:
      // any attribute can be dynamic. Set attr only on actual change.
        const attr = rule.substring(2) // eslint-disable-line no-case-declarations

        if (result != nd.getAttribute(attr))
          nd.setAttribute(attr, result)
    }

    return insert
  }

  // updates all DOM nodes that reference the given property
  function renderProperty(propName, oldValue) {
    const propMapping = mapping.get(propName)

    // update the nodes that depend on this property
    if (propMapping) {
      for (const exprGrp of propMapping) {
        if (exprGrp.listPath) {
          processLoop(exprGrp, oldValue.length)
        }
        else if (exprGrp.scopeId) {
          // run expr update on all scoped nodes.
          const localScope =  scopes.get(exprGrp.scopeId)

          // expression can be used on multiple nodes, process them all.
          for (let j = 0; j < exprGrp.nds.length; j++) {
            const { nd: tmplNd, rule } = exprGrp.nds[j]
            const tmplNdData = localScope.nodes.get(tmplNd)

            // there may be no node to update (e.g. no avails).
            if (!tmplNdData) continue

            const mappedNodes = tmplNdData.nds
            const fn = fnMap.get(exprGrp.expr)
            // process each iteration of the looped list

            for (let i = 0; i < localScope.items.length; i++) {
              // compute function for current iteration
              const res = fn.call(null, app.data, app.store, localScope.items[i], i)

              // x-if directives require instanciating or removing a node.
              // other rules just update an attribute value or text content.
              if (rule === 'x-if') {
                // switch back to node if it was replaced by a comment.
                if (res) {
                  const comm = mappedNodes[i]
                  let nd = ifMap.get(comm)

                  if (nd) {
                    // node exists: clean map and reassign it in mapped node list
                    ifMap.delete(comm) // no need for comment -> nd entry anymore
                    mappedNodes[i] = nd
                  }
                  else {
                    // no ref to actual node: create it. Assignment in mapped node list is done in renderNode.
                    nd = tmplNd.cloneNode(true)

                    renderNode(nd, exprGrp.scopeId, [localScope.items[i], i], tmplNd )
                    bindEvents(nd, {
                      [localScope.itemName]: localScope.items[i],
                      [localScope.iterName]: i,
                    })
                  }
                  comm.replaceWith(nd)
                }
                // condition is false and node exists: replace with comment.
                else if (!res && mappedNodes[i]) {
                  const comm = document.createComment('')

                  mappedNodes[i].replaceWith(comm)
                  ifMap.set(comm, mappedNodes[i]) // keep track of replaced node for later re-insertion
                  mappedNodes[i] = comm // reference the comment in nodelist
                }
              }
              else if (mappedNodes[i]) {
                updateNode(mappedNodes[i], rule, res)
              }
            }
          }
        }
        else {
          const fn = fnMap.get(exprGrp.expr)
          let res // lazily evaluated when needed

          // flag to note if function has run already. Cannot use any default value for res,
          // as that value would mean re-evaluation for each node.
          let evaluated = false

          // update each node according to expression results
          // & its associated rule.
          for (const elem of exprGrp.nds) {
            // evaluate if node is in document or has if/else directive to insert it.
            if (elem.nd.isConnected || elem.rule === 'x-if' || elem.rule === 'x-else') {
              if (!evaluated) {
                res = fn.call(null, app.data, app.store)
                evaluated = true
              }
              updateNode(elem.nd, elem.rule, res)
            }
          }
        }
      }
    }

    // process watchers
    const fns = watchers.get(propName)

    if (fns) {
      for (let i = 0; i < fns.length; i++) {
        fns[i].call(null, app.store[propName], oldValue)
      }
    }
  }

  const handler = {
    // setter interception
    set: function(obj, prop, value) {
      if (obj[prop] === value) return true

      performance.mark('proxy-set-start')
      const old = obj[prop]

      obj[prop] = value // set value

      renderProperty(prop, old)

      performance.measure('proxy-set-end', 'proxy-set-start')
      const duration = performance.getEntriesByName('proxy-set-end', 'measure').pop().duration

      if (player.data.env === 'dev')
        console.debug(`proxy set [${prop}]: ${duration.toFixed(1)}ms`) // eslint-disable-line no-console

      return true
    },
  }

  // public interface
  return {
    start,
    watch,

    // expose internal state for debug
    mapping,
    scopes,
    eventMapping,
    domStates,
    fnMap,
    watchers,
    ifMap,
  }
})()
